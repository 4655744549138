<template>
    <div class="bg-card-bg rounded-2xl p-10 flex flex-col items-center space-y-4 max-w-sm mx-auto">
      <div class="bg-card-bk rounded-full p-4 flex items-center justify-center">
       <img src="@/assets/machine.png" alt="icon" class="w-16">
      </div>
      <p class="text-center text-white">
        {{ description }}
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: "IconCard",
    props: {
    description: {
      type: String,
      required: true,
    },
  }
}
  
  </script>
  