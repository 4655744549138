import { createStore } from 'vuex';
import chat from './modules/chat';




export const store = createStore({
    modules: {
      chat,
    },
})
