import chatServices from '../../services/chatServices';

const isGreeting = ['hello', 'hi', 'hello?', 'hi?', '?', 'thanks', 'good morning', 'good afternoon'];
const specialCharacterRegex = /[^a-zA-Z0-9\s]/; 

const getDefaultState = () => ({
  messages: [],
  selectedModel: null, 
  loading: false,
});

const state = getDefaultState();

const actions = {
  async sendQuery({ commit, state }, message) {
    const isSpecificModel = state.selectedModel !== null;
    const lowerCaseMessage = message.toLowerCase();

    const greet = isGreeting.includes(lowerCaseMessage) || specialCharacterRegex.test(message);

    const query = isSpecificModel && !greet
      ? `${message} in ${state.selectedModel}`
      : message;

    try {
      commit('setLoading', true);
      const params = { query };
      const response = await chatServices.getResponse(params);
      commit('setLoading', false);

      commit('addMessage', { sender: 'ai', text: '', isStreaming: true });

      for (const char of response.response) {
        await new Promise((resolve) => setTimeout(resolve, 50)); 
        commit('appendToLastMessage', char); 
      }
     
      return response; 
    } catch (error) {
      commit('addMessage', { sender: 'ai', text: error.message });
      throw error;
    }
  },
  setSelectedModel({ commit }, model) {
    commit('setSelectedModel', model);
  },

  resetChatState({ commit }) {
    commit('resetState');
  },
};

const mutations = {
  addMessage(state, message) {
    state.messages.push(message);
  },
  appendToLastMessage(state, char) {
    const lastMessage = state.messages[state.messages.length - 1];
    if (lastMessage && lastMessage.isStreaming) {
      lastMessage.text += char;
    }
  },
  removeMessage(state, messageId) {
    state.messages = state.messages.filter((msg) => msg.id !== messageId);
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
  setSelectedModel(state, model) {
    state.selectedModel = model;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  messages: (state) => state.messages,
  selectedModel: (state) => state.selectedModel,
  isLoading: (state) => state.loading,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
