import axios from 'axios';

const API_URL = 'https://coffeeai.techscale.io';

const getResponse = async (params) => {
  try {
    const response = await axios.post(`${API_URL}/docs_query`, params);
    return response.data;
  } catch (error) {
    throw new Error('Network Error: Check your connection');
  }
};

export default {
  getResponse,
};








// const getResponse = async (query) => {
//   try {
//     const response = await axios.post(`${API_URL}/docs_query`, { query });
//     return response.data;
//   } catch (error) {
//     throw new Error('Network Error: Check your connection');
//   }
// };

















// import axios from 'axios';

// const API_URL = 'https://coffeeai.techscale.io/docs_query';

// const getResponse = async (query) => {
//   try {
//     const response = await axios.post(API_URL, { query });
//     return response.data;
//   } catch (error) {
//     throw new Error('Network Error: Check your connection');
//   }
// };

// export default {
//   getResponse,
// };
