import { createRouter , createWebHistory } from "vue-router"; 
import HomeView from "../components/views/HomeView.vue";
 


const routes = [
    {
        path: '/', 
        name: 'homeView',
        component: HomeView
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import('../components/views/chatBot.vue'),
        props: route => ({ model: route.params.model }),
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes,
  });

export default router;