<template>
  <div class="w-full min-h-screen bg-background-bg">
    <div class="flex flex-col md:flex-row items-center justify-center text-center p-4 animate-slide-up">
      <img src="@/assets/beardLogo.png" alt="Logo" class="w-16 pr-4" />
      <p class="text-xl m-1 text-white font-bold">{{ message }}</p>
    </div>

    <div class="w-full flex justify-center item-center bg-custom-bg  px-4 rounded-3xl ">
      <div class="flex  md:flex-row w-full max-w-[700px] lg:max-w-full justify-center p-6 gap-4">
        <div class=" w-full max-w-[437px]">
          <h1 class="font-bold  text-2xl mob:text-sm">
            Your Go-To for All Coffee Machines
          </h1>
          <p class=" mt-3 text-2xl mob:text-sm">
            Ask any question about our machines, and we will provide the
            assistance you need.
          </p>
          <button
            class="px-4 py-3 bg-black text-white font-semibold rounded-lg shadow-md mt-6 text-[24px] mob:text-[16px]"
            @click="startNewChat"
          >
            + New Chat
          </button>
        </div>
        <img src="@/assets/aiChat.png" alt="AI Chat" class=" w-full max-w-[200px] mob:w-[132px]"/>
    </div>
    </div>
   

    <div class="text-center mt-6">
      <h1 class="text-gray-300 text-2xl">Machines Model</h1>
      <p class="text-gray-500 mt-3">
        Please select the model you are experiencing an issue with.
      </p>
    </div>
    <div class="mt-4 flex flex-wrap justify-around">
      <div
        v-for="(model, index) in machineModels"
        :key="index"
        class=" w-full md:w-1/3 mb-4 p-2 cursor-pointer mob:w-1/2"
      >
        <machineCard
          :description="model.description"
          @click="startChat(model.name)"
        />
      </div>
      <!-- <div class="mt-4 flex flex-wrap justify-around">
      <machineCard class="w-1/3 mb-4 mob:w-1/2 cursor-pointer" description="A400" @click="startChat('A400')" />
      <machineCard class="w-1/3 mb-4 mob:w-1/2 cursor-pointer" description="A800" @click="startChat('A800')" />
      <machineCard class="w-1/3 mb-4 mob:w-1/2 cursor-pointer" description="A1000" @click="startChat('A1000')" />
    </div> -->
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'; 
import machineCard from '../machineCard.vue';

const message = "AI CHATBOT";
const router = useRouter();

const machineModels = [
  { name: 'A400', description: 'A400' },
  { name: 'A800', description: 'A800' },
  { name: 'A1000', description: 'A1000' },
  { name: 'A300', description: 'A300' },
  { name: 'SB1200', description: 'SB1200' },
  { name: 'Mytico Due', description: 'Mytico Due' },
  { name: 'Mytico Vario', description: 'Mytico Vario' },
  // { name: 'Rex-royal', description: 'Rex-royal' },
  // { name: 'yunio H2', description: 'yunio H2' },
  // { name: 'ENG_Yunio_X20', description: 'ENG_Yunio_X20' },
  // { name: 'Franke-fejlkoder', description: 'Franke-fejlkoder' },
  // { name: 'Rex-Royal-S500', description: 'Rex-Royal-S500' },
  // { name: 'Rex-Royal_S300', description: 'Rex-Royal_S300' },
  // { name: 'SW_V1.20_S200', description: 'SW_V1.20_S200' },
  // { name: 'VIVA 01', description: 'VIVA 01' },
];

const startChat = (model) => {
  router.push({ name: 'chat', query: { model } });
};

const startNewChat = () => {
  router.push({ name: 'chat', query: {} });
};
</script>

<style scoped>
@keyframes slideUp {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-up {
  animation: slideUp 1s ease-out;
}
</style>
